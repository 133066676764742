.pokemon-card {
  width: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.pokemon-card__figure {
  width: 98px;
  height: 98px;
  border: 1px solid #cccccc;
}

.pokemon-card__button {
  display: inline-block;
  padding: 8px 20px;
  background: #fecb3c;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #000000;
  font-weight: 300;
  text-decoration: none;
}

.pokemon-card__button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* MINI CARD */
.pokemon-card--mini {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
}

.pokemon-card--mini .pokemon-card__title {
  padding-top: 5px;
  color: inherit;
  font-family: "Pokemon Solid";
  font-size: 1rem;
  letter-spacing: 2px;
}

/* FULL CARD */
.pokemon-card--full {
  padding: 45px;
}

.pokemon-card--full .pokemon-card__title {
  margin-bottom: 30px;
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 700;
}

.pokemon-card--full .pokemon-card__basic-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 30px;
}

.pokemon-card--full .pokemon-card__figure {
  margin-right: 30px;
}

.pokemon-card--full .pokemon-card__metadata {
  flex-grow: 1;
  list-style-type: none;
}

.pokemon-card--full .pokemon-card__metadata li {
  margin-bottom: 10px;
}

.pokemon-card--full .pokemon-card__metadata li:last-child {
  margin-bottom: 0;
}

.pokemon-card__stats {
  margin-bottom: 30px;
  padding: 0 30px;
}

.pokemon-card--full .pokemon-card__stats-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.pokemon-card--full .pokemon-card__stats-row:last-child {
  margin-bottom: 0;
}

.pokemon-card--full .pokemon-card__stats-name {
  width: 150px;
}

.pokemon-card--full .pokemon-card__stats-value {
  padding: 0 2px 1px 3px;
  background-color: #fecb3c;
  border-radius: 2px;
  font-size: 0.8rem;
}

.pokemon-card--full .pokemon-card__footer {
  padding: 0 30px;
}

/* REGULAR CARD */
.pokemon-card--regular {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pokemon-card--regular .pokemon-card__title {
  margin-bottom: 15px;
  color: inherit;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
}

.pokemon-card--regular .pokemon-card__figure {
  margin-bottom: 15px;
}

.pokemon-card--regular .pokemon-card__metadata {
  margin-bottom: 15px;
}
